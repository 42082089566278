import(/* webpackMode: "eager" */ "/vercel/path0/app/page.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/aboutUs/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/title/Title.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/banners/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/cards/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/projects/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/howWeWork/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/aboutUs/picture.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/banners/Miras.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/banners/second.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/banners/third.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/cards/first.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/cards/second.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/cards/third.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/camera.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/gear.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/pencil.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/pin.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/puzzle.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/projects/Beauty.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/projects/Ecommerce.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/projects/Fashion.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/projects/HomeRemodeling.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/projects/Restaurant.png");
import(/* webpackMode: "eager" */ "/vercel/path0/public/projects/Software.png")